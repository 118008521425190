<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Hyvityslaskut</div>

    <v-card class="mt-2">
      <v-container>
        <!-- Search by dates -->
        <search-by-dates v-show="tab === 0"></search-by-dates>

        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              :label="getInvoiceSearchLabelByService('refundInvoice')"
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense class="mt-1">
          <v-col cols="6" md="4">
            <v-btn class="mr-5 mb-2" color="info" @click.prevent="getDataFromApi()">Etsi</v-btn>
            <v-btn class="mb-2" color="error" outlined @click="resetForms()">Tyhjennä</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="invoices"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <template #top>
          <!-- TABS -->
          <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="item in tabs" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>

        <!-- CreatedBy -->
        <template #[`item.createdBy`]="{ item }">
          <span
            class="primary--text"
            style="cursor: pointer"
            @click="switchToAccount(item.createdBy, '/refund-invoices')"
            ><strong>{{ item.createdBy.name }}</strong></span
          >
        </template>

        <!-- InvoiceNumber -->
        <template #[`item.invoiceNumber`]="{ item }">
          <span># {{ item.invoiceNumber ? item.invoiceNumber : "-" }} </span>
        </template>

        <!--  billDate -->
        <template #[`item.billDate`]="{ item }">
          <span>{{ formatDate(item.billDate) }}</span>
        </template>

        <!--  dueDate -->
        <template #[`item.dueDate`]="{ item }">
          <span>{{ formatDate(item.dueDate) }}</span>
        </template>

        <!-- totalAmount -->
        <template #[`item.totalAmount`]="{ item }">
          <div style="display: flex">
            <product-list :item="item" color="error"></product-list>

            <span
              v-if="item.paidAmounts.length > 0"
              style="cursor: pointer"
              @click="
                selected = { ...item };
                dialogPaidAmounts = true;
              "
              ><v-icon small>mdi-comment-edit-outline</v-icon></span
            >
          </div>
        </template>

        <!-- Tenant -->
        <template #[`item.tenant`]="{ item }">
          <div v-if="invoiceTenant(item).isReceiver">
            <span
              class="primary--text"
              style="cursor: pointer"
              @click="
                switchToAccount(
                  item.createdBy,
                  `/tenant/overview/${invoiceTenant(item).tenant.tenantId._id}`
                )
              "
              >{{ item.tenant.name }}</span
            >
          </div>

          <div v-else>
            <span>{{ item.tenant.name }}</span>
            <br />
            <span
              class="primary--text"
              style="cursor: pointer"
              @click="
                switchToAccount(
                  item.createdBy,
                  `/tenant/overview/${invoiceTenant(item).tenant.tenantId._id}`
                )
              "
              >{{ invoiceTenant(item).tenant.tenantId.name }}</span
            >
          </div>
        </template>

        <!-- Apartment Address -->
        <template #[`item.contract`]="{ item }">
          <span
            class="primary--text"
            style="cursor: pointer"
            @click="
              switchToAccount(
                item.createdBy,
                `/apartment/overview/${item.contract.apartment.id._id}`
              )
            "
            >{{ item.contract.apartment.id.address
            }}<span v-if="item.contract.apartment.id.apartmentNumber"
              >, {{ item.contract.apartment.id.apartmentNumber }}
            </span>
          </span>
          <div v-for="(room, idx) in getRentedRooms(item.contract.apartment)" :key="idx + 'a'">
            <p class="draft--text" style="margin-bottom: -2px !important">{{ room.name }}</p>
          </div>
        </template>

        <!-- SendTypes -->
        <template #[`item.sendType`]="{ item }">
          <span
            ><v-icon small>{{ getSendIcon(item.sendType) }}</v-icon>
          </span>
        </template>

        <!-- Outgoing payments -->
        <template #[`item.outgoingPayments`]="{ item }">
          <span v-if="item.outgoingPayments.inUse" class="font-weight-bold success--text"
            >Käytössä</span
          >
          <span v-else class="font-weight-bold error--text">Ei käytössä</span>
        </template>

        <!-- Comments -->
        <template #[`item.lastComment`]="{ item }">
          <span class="mr-1">{{ formatCommentsInTableRow(item.comments) }}</span>
          <span
            v-if="item.comments.length > 0"
            style="cursor: pointer"
            @click="(selected = item), (dialogComments = true)"
            ><v-icon small>mdi-comment-edit-outline </v-icon></span
          >
        </template>

        <!-- Actions -->

        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="
                isAuthorized('invoice', 'read') &&
                !item.active &&
                !hasServiceId(item) &&
                canDownloadPreviewInvoice(item)
              "
              class="grid-item"
              title="Esikatsele"
              small
              @click="
                downloadItem = { ...item };
                downloadPdf(true, null);
              "
              >mdi-magnify</v-icon
            >

            <v-icon
              v-if="isAuthorized('invoice', 'read') && hasServiceId(item)"
              title="Lataa pdf"
              small
              @click="
                downloadItem = { ...item };
                downloadPdf(false, null);
              "
              >mdi-file-pdf</v-icon
            >
          </div>
        </template>

        <template #no-data>
          <h2>Ei hyvityslaskuja</h2>
        </template>
      </v-data-table>

      <!-- Dialog comments -->
      <comments-dialog v-model="dialogComments" :invoice="selected"></comments-dialog>

      <!-- Dialog PaidAmounts -->
      <paid-amounts v-model="dialogPaidAmounts" :invoice="selected"></paid-amounts>
    </v-card>
  </div>
</template>

<script>
import PaidAmounts from "@/components/Invoice/PaidAmountsDialog";
import CommentsDialog from "@/components/Invoice/CommentsDialog";
import SearchByDates from "../../../components/Invoice/SearchByDates.vue";
import mixins from "@/mixins/mixins";
import invoiceMixins from "@/mixins/invoiceMixins";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import validations from "@/validations";
import globalValues from "@/configs/globalValues";
import invoiceHelpers from "@/utils/invoiceHelpers";
import ProductList from "@/components/Invoice/TableComponents/ProductList";

export default {
  title: "Hyvityslaskut",
  mixins: [mixins, invoiceMixins],
  components: {
    PaidAmounts,
    CommentsDialog,
    SearchByDates,
    ProductList,
  },
  data: () => ({
    tabs: ["Laskut", "Luonnokset"],
    dialogComments: false,
    dialogPaidAmounts: false,
    selected: { comments: [], sendingErrors: [], paidAmounts: [] },
    search: "",
    downloadItem: {},
    loading: true,
    options: {},
    menu: false,
    payDate: "",
    dateFormattedPayDate: "",
    allHeaders: [
      {
        text: "Tili",
        value: "createdBy",
        show: [0, 1, 2],
      },
      {
        text: "Laskun nro.",
        value: "invoiceNumber",
        show: [0],
      },
      {
        text: "Laskun pvm.",
        value: "billDate",
        show: [0, 1],
      },
      {
        text: "Eräpäivä",
        value: "dueDate",
        show: [0, 1],
      },
      {
        text: "Sopimus",
        value: "contract.contractNumber",
        show: [0, 1],
      },
      {
        text: "Saaja",
        value: "tenant",
        show: [0, 1],
      },
      {
        text: "Kohde",
        value: "contract",
        show: [0, 1],
      },
      {
        text: "Toimitus",
        value: "sendType",
        show: [1],
      },
      {
        text: "Hyvitys",
        value: "totalAmount",
        show: [0, 1],
      },
      {
        text: "Lähtevät maksut",
        value: "outgoingPayments",
        show: [7],
        sortable: false,
      },
      {
        text: "Tila",
        value: "lastComment",
        show: [0],
      },
      {
        text: "Toiminnot",
        value: "actions",
        show: [0, 1],
        sortable: false,
      },
    ],
    validations,
    globalValues,
  }),

  computed: {
    ...mapState("invoice", ["totalLength", "invoices", "searchByDates"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
    ...mapState("account", ["currentUser"]),

    headers() {
      return this.allHeaders.filter((el) => {
        if (this.tab == 0) {
          return (
            el.show.includes(0) ||
            (el.show.includes(7) && this.currentUser.currentAccount.settings.outgoingPayments)
          );
        }

        if (this.tab == 1) {
          return (
            el.show.includes(1) ||
            (el.show.includes(7) && this.currentUser.currentAccount.settings.outgoingPayments)
          );
        }
      });
    },

    tab: {
      get() {
        return this.$store.state.browsehistory.tab;
      },
      set(val) {
        this.$store.state.browsehistory.tab = val;
      },
    },
  },

  watch: {
    tab() {
      this.getDataFromApi();
    },

    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },

    payDate() {
      this.dateFormattedPayDate = this.formatDate(this.payDate);
    },
  },

  created() {
    this.resetSearchByDates("dueDate");
  },

  methods: {
    ...mapActions("account", ["switchAccount"]),
    ...mapActions("invoice", ["getAllInvoices", "deleteInvoice"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("invoice", ["resetSearchByDates"]),
    ...mapMutations("pdfViewer", ["openViewer"]),
    ...mapGetters("account", ["getSelectedAccounts"]),

    async getDataFromApi() {
      this.loading = true;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      const url = this.getInvoiceUrl();
      await this.getAllInvoices({ url, data: { accountSelector: this.getSelectedAccounts() } });

      this.loading = false;
    },

    getInvoiceUrl() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const search = this.search.trim().toLowerCase();
      let additionalQuery = `&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      let url;

      if (this.tab == 0) {
        const start = this.searchByDates.startDate;
        const end = this.searchByDates.endDate;
        const searchBy = this.searchByDates.searchBy;
        additionalQuery =
          additionalQuery + `&startDate=${start}&endDate=${end}&searchBy=${searchBy}`;

        url = "/api/v1/invoices?refundInvoice=true&sendDate=exists";
      }
      if (this.tab == 1) {
        url = "/api/v1/invoices?refundInvoice=true&sendDate=null";
      }

      return url + additionalQuery;
    },

    async downloadPdf(preview, statusCode) {
      try {
        const data = await this.downloadInvoice(this.downloadItem, preview, statusCode, true);

        this.openViewer({
          data,
          name: `Hyvityslasku__${this.downloadItem.invoiceNumber || "esikatselu"}.pdf`,
        });
      } catch (err) {
        const errorMsg = err?.response?.data?.message || "";
        if (preview) this.showPopup(`Lataaminen ei onnistunut. ${errorMsg}`, "error");
        else this.showPopup(`${errorMsg}. Kokeile vaihtaa laskun tyyppiä`, "error");
      }
    },

    invoiceTenant(invoice) {
      return invoiceHelpers.getInvoiceTenant(invoice);
    },

    resetForms() {
      this.resetSearchByDates("dueDate");
      this.search = "";
      this.getDataFromApi();
    },
  },
};
</script>

<style scoped></style>
